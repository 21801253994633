import type { RDMDOpts as Opts, RDMDProps as Props } from '@readme/iso';
import type { MDXModule } from 'mdx/types';

import * as rmdx from '@readme/mdx';
import React, { useEffect, useMemo, useState } from 'react';

import rdmdComponentOverrides from '@core/utils/rdmdComponentOverrides';

import './style.scss';

type RunResults = Awaited<ReturnType<typeof rmdx.run>>;

export async function exec(body: string, opts: Opts, cb: (module: RunResults) => void) {
  const customBlocksCompiled = {};
  const customBlocks = {};

  const promises = Object.entries(opts.components || {}).map(async ([tag, source]): Promise<[string, MDXModule]> => {
    customBlocksCompiled[tag] = rmdx.compile(source);
    const mod = await rmdx.run(customBlocksCompiled[tag]);

    return [tag, mod];
  });

  (await Promise.all(promises)).forEach(([tag, node]) => {
    customBlocks[tag] = node;
  });

  const components = { ...rdmdComponentOverrides, ...customBlocks };
  const vfile = rmdx.compile(body, { ...opts, components: customBlocksCompiled });
  const module = await rmdx.run(vfile, { ...opts, components });

  cb(module);

  return module;
}

function useRMDX(body: string, _, optsParam: Opts) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const opts = useMemo(() => optsParam, [JSON.stringify(optsParam)]);
  const [Content, setContent] = useState<RunResults>();

  useEffect(() => {
    try {
      exec(body, opts, content => setContent(content));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [body, opts]);

  return Content;
}

export const TOC = ({ body, children, dehydrated, opts = {} }: Props) => {
  const Content = useRMDX((body || children) as string, true, opts);

  return Content ? <Content.Toc /> : <div dangerouslySetInnerHTML={{ __html: dehydrated || '' }} />;
};

const RMDX = ({
  body,
  children,
  className,
  dehydrated,
  opts = {},
  skipBaseClassName = false,
  Tag = 'div',
  ...rest
}: Props) => {
  const Content = useRMDX((body || children) as string, false, opts);
  const classes = [skipBaseClassName !== true && 'markdown-body', className || ''];
  const props = { ...rest, className: `rm-Markdown ${classes.filter(c => c).join(' ')}`, 'data-testid': 'RDMD' };

  return Content ? (
    <Tag {...props}>
      <Content.default />
    </Tag>
  ) : (
    <Tag {...props} dangerouslySetInnerHTML={{ __html: dehydrated }} />
  );
};

export default RMDX;
